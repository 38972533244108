import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import RecomendedItem from "./RecomendedItem";
function RecomItems({recommendedItemLists}) {
const [slideToShow,SetSlideToShow]= useState(3);

useEffect(()=>{
  const handleSize = ()=>{
        SetSlideToShow(Math.ceil((window.innerWidth)/440))
  }
  window.addEventListener('resize',handleSize);
  handleSize();
  return()=>{
    window.removeEventListener('resize',handleSize);
  }
},[])
 
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slideToShow,
    slidesToScroll: slideToShow,
    autoplay: true,      
    autoplaySpeed: 5000   ,
    className:"trending-slider"
  };
 
  return (
    <div className="container">
      <div class="pt-4 pb-2 title d-flex align-items-center">
                <h5 class="m-0">Trending this week</h5>
                <a class="font-weight-bold ml-auto" href="/product">View all <i class="feather-chevrons-right"></i></a>
            </div>

      <Slider {...settings}>
      {
        recommendedItemLists.map((item,index) => (
              <RecomendedItem 
              key ={index}
              recommendedItem={item}
             
               />))}
      </Slider>
  
    </div>
  );
}

export default RecomItems;
