import React, { useState,useEffect,Fragment } from 'react'
import { Image, Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import logo from './logo_web.png';
import axios from 'axios';


  const Invoice = (props) => {
    

    const [order, setOrder] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [RestaurantInfo ,setRestaurentInfo] = useState({});

    useEffect(() => {
        setOrder(props.data);
        setOrderItems(props.data.items);

        axios.get('http://localhost:3000/api/restaurent')
        .then((response)=>{
          setRestaurentInfo(response.data.restaurants[0]);
          console.log(response.data.restaurants[0])
        })
        .catch((error)=>{
          console.log(error);
        })

    });
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString("en-GB"); 
      };

      
         
    const styles = StyleSheet.create({
        page: {fontSize: 11,paddingTop: 20,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column' },

        spaceBetween : {flex : 1,flexDirection: 'row',alignItems:'center',justifyContent:'space-between',color: "#3E3E3E" },

        titleContainer: {flexDirection: 'row',marginTop: 24},
        
        logo: { width: 90 },

        reportTitle: {  fontSize: 16,  textAlign: 'center' },

        addressTitle : {fontSize: 11,fontStyle: 'bold'}, 
        
        invoice : {fontWeight: 'bold',fontSize: 20},
        
        invoiceNumber : {fontSize: 11,fontWeight: 'bold'}, 
        
        address : { fontWeight: 400, fontSize: 10},
        
        theader : {marginTop : 20,fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theader2 : { flex:2, borderRightWidth:0, borderBottomWidth:1},

        tbody:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},

        total:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1.5, borderColor : 'whitesmoke', borderBottomWidth:1},

        tbody2:{ flex:2, borderRightWidth:1, }

        
    });

    const InvoiceTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.reportTitle}>{RestaurantInfo.Name}</Text>
            </View>
        </View>
        
    );

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={styles.invoice}>Invoice </Text>
                    <Text style={styles.invoiceNumber}>Order : {order.orderId} </Text>
                </View>
                <View>
                    <Text style={styles.addressTitle}>{RestaurantInfo.Name}</Text>
                    <Text style={styles.addressTitle}>{RestaurantInfo.Address}</Text>
                </View>
            </View>
        </View>
    );

    const UserAddress = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{maxWidth : 200}}>
                    <Text style={styles.addressTitle}>Bill to: </Text>
                    <Text style={styles.address}>
                        {order.name}
                    </Text>
                </View>
                <Text style={styles.addressTitle}>Order date:{formatDate(order.createdAt)}</Text>
            </View>
        </View>
    );


    const TableHead = () => (
        <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Items</Text>   
            </View>
            <View style={styles.theader}>
                <Text>Price</Text>   
            </View>
            <View style={styles.theader}>
                <Text>Qty</Text>   
            </View>
            <View style={styles.theader}>
                <Text>Amount</Text>   
            </View>
        </View>
    );


    const TableBody = () => (

        orderItems.map((item)=>(
           
        <Fragment key={item.name}>
            <View style={{ width:'100%', flexDirection :'row'}}>
                <View style={[styles.tbody, styles.tbody2]}>
                    <Text >{item.name}</Text>   
                </View>
                <View style={styles.tbody}>
                    <Text>{item.price} </Text>   
                </View>
                <View style={styles.tbody}>
                    <Text>{item.quantity}</Text>   
                </View>
                <View style={styles.tbody}>
                    <Text>{(item.price * item.quantity).toFixed(2)}</Text>   
                </View>
            </View>
        </Fragment>
       ))
    );

    const TableTotal = () => (
        <View style={{ width:'100%', flexDirection :'row'}}>
            <View style={styles.total}>
                <Text></Text>   
            </View>
            <View style={styles.total}>
                <Text> </Text>   
            </View>
            <View style={styles.tbody}>
                <Text>Total</Text>   
            </View>
            <View style={styles.tbody}>
                <Text>
                    {order.totalPrice}
                </Text>  
            </View>
        </View>
    );
    const InvoiceFoot = () => (
        <View style={styles.titleContainer}>
          <View style={styles.spaceBetween}>
            <View>
                <Text style={styles.addressTitle}>{RestaurantInfo.Name}</Text>
                <Text style={styles.addressTitle}>{RestaurantInfo.Address}</Text>
            </View>
           </View>
        </View>
        
    );
    return (
       
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle  />
                <Address/>
                <UserAddress/>
                <TableHead/>
                <TableBody/>
                <TableTotal/>
                <View fixed>
                    <InvoiceFoot/>
                </View>
                
            </Page>
        </Document>
          
    )
}



export default Invoice