import React, { useContext, createContext, useState } from "react";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [searchData, setSearchData] = useState("");
    const [filtersubCategory, setsubCategory] = useState("");
    
    const [filter, setFilterChange] = useState("0");
    

    const handleFilterChange = (e) => {
        setFilterChange(e.target.value);
    }

    const handleSearchChange = (e) => {
        setSearchData(e.target.value);
    }
    const handleSubCategoryChange = (name) => {
        console.log(name);
        setsubCategory(name);
    }
    

   

    const isAllSelected = filter === "0" && (!searchData|| !filtersubCategory);

    return (
        <SearchContext.Provider value={{
            searchData,
            filtersubCategory,
            setSearchData,
            handleSearchChange,
            filter,
            handleFilterChange,
            isAllSelected,
            handleSubCategoryChange,

        }}>
            {children}
        </SearchContext.Provider>
    )
}

export const useSearch = () => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearch must be used within a CartProvider');
    }
    return context;
}
