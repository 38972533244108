
import React, { createContext, useContext, useState } from 'react';



const CartContext = createContext();


export const CartProvider = ({ children }) => {
  const [totalPrice, setTotalPrice] = useState(sessionStorage.getItem('cartTotalValue') == null ? 0 : sessionStorage.getItem('cartTotalValue'));
  const [itemQuantities, setItemQuantities] = useState( sessionStorage.getItem('cartQuantityValue') == null ? {} : JSON.parse(sessionStorage.getItem('cartQuantityValue')));

  
  const updateTotalPrice = (priceChange) => {
     const numericChange = parseFloat(priceChange);
     console.log(numericChange);
     if (!isNaN(numericChange)) {
      setTotalPrice((prevTotal) => prevTotal + numericChange);
    } else {
      console.error('Invalid numeric value:', priceChange);
    }
  };

  const resetTotalPrice = () => {
    setTotalPrice(0);
  };

  const updateQuantity = (id, newQuantity,price) => {
    console.log(newQuantity);
    setItemQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: {quantity:newQuantity,price:price}
    }));
    let total=0;
    const cartItems = {...itemQuantities,[id]: {quantity:newQuantity,price:price}};
    Object.keys(cartItems).forEach(element => {
      total=total+(cartItems[element]?.quantity*parseFloat(cartItems[element]?.price));
    });
    setTotalPrice(total.toFixed(2));
    sessionStorage.setItem("cartQuantityValue", JSON.stringify(cartItems));
    sessionStorage.setItem("cartTotalValue",total.toFixed(2));
  };


  return (
    <CartContext.Provider value={{ totalPrice, updateTotalPrice, resetTotalPrice,itemQuantities, setItemQuantities,updateQuantity }}>
      {children}
    </CartContext.Provider>
  );
};


export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
