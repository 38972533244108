import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoClose } from "react-icons/io5";
import { jwtDecode } from 'jwt-decode';
import Invoice from './Invoice'
import { HiOutlineDownload, HiOutlinePrinter } from 'react-icons/hi'
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer'

const styles = {
   btn: { borderRadius: '3px', border: '1px solid gray', display: 'flex', alignItems: 'center', gap: '2px', padding: '3px', fontSize: '11px', color: '#4f4f4f', fontWeight: 600, cursor: 'pointer', userSelect: 'none' }
}

const Orders = ({ SetOrdersVisible, setPageVisible }) => {
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [activeTab, setActiveTab] = useState('on Process');
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const loggedinUser = () => {
      const userToken = localStorage.getItem('token');
      let userInfo = null;

      if (userToken) {
        try {
          const decodedToken = jwtDecode(userToken);
          console.log('Decoded token:', decodedToken);

          userInfo = decodedToken.phoneNumber;
        } catch (error) {
          console.log('Error decoding token:', error);
        }
      }
      return userInfo;

    };

    const userInfo = loggedinUser();
    console.log('phno', userInfo);
    if (userInfo) {
      fetchOrderHistory(userInfo);
    } else {
      setOrderHistoryData([]);
    }
  }, [activeTab]);

  const fetchOrderHistory = async (userInfo) => {
    console.log('number', userInfo);
    try {
      console.log('dynamic number', 'http://localhost:3000/api/Cart?phoneNumber=' + userInfo);

      const response = await axios.get('http://localhost:3000/api/Cart?phoneNumber=' + userInfo);
      console.log('number api', response);
      const order = response.data.cart;
      let filteredOrders = order;
      if (activeTab === 'on Process') {
        filteredOrders = order.filter(order => order.orderstatus === 'New Order');
      } else if (activeTab === 'completed') {
        filteredOrders = order.filter(order => order.orderstatus === 'completed');
      } else {
        filteredOrders = order.filter(order => order.orderstatus === 'Canceled');
      }
      

      setOrderHistoryData(filteredOrders);

    } catch (error) {
      console.log('Error fetching order history:', error);

    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-GB");
  };

  return (
    <>
      <section className="py-4 osahan-main-body d-block d-md-block">
        <div className="container" style={{ zIndex: 1500 }}>
          <div className="p-3 d-flex align-items-center ">
            <h4 className="font-weight-bold m-0 text-red">My Orders</h4>
            <span className='ml-auto ' style={{ fontSize: '20px' }}><IoClose onClick={() => SetOrdersVisible(false)} /></span>
          </div>
          <div className="row">
            <div className="col-md-3 mb-3 d-block">
              <div>
              </div>
              <ul className="nav nav-tabsa custom-tabsa border-0 flex-column bg-white rounded overflow-hidden shadow-sm p-2 c-t-order" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className={`nav-link border-0 text-dark py-3 ${activeTab === 'completed' ? 'active' : ''}`}
                    onClick={() => setActiveTab('completed')} id="completed-tab" data-toggle="tab" href="#completed" role="tab" aria-controls="completed" aria-selected="true">
                    <i className="feather-check mr-2 text-success mb-0"></i> Completed
                  </a>
                </li>
                <li className="nav-item border-top " role="presentation">
                  <a className={`nav-link border-0 text-dark py-3 ${activeTab === 'on Process' ? 'active' : ''}`} id="progress-tab"
                    onClick={() => setActiveTab('on Process')} data-toggle="tab" href="#progress" role="tab" aria-controls="progress" aria-selected="false">
                    <i className="feather-clock mr-2 text-warning mb-0"></i> On Progress
                  </a>
                </li>
                <li className="nav-item border-top" role="presentation">
                  <a className={`nav-link border-0 text-dark py-3 ${activeTab === 'Canceled' ? 'active' : ''}`}
                    onClick={() => setActiveTab('Canceled')} id="canceled-tab" data-toggle="tab" href="#canceled" role="tab" aria-controls="canceled" aria-selected="true">
                    <i className="feather-x-circle mr-2 text-danger mb-0"></i> Canceled
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-9" id="myTabContent">
              {orderHistoryData.map((order, id) => (
                <div className="tab-pane fade show active" id={`order-${order.orderId}`} role="tabpanel" aria-labelledby="completed-tab" key={id}>
                  <div className="order-body">
                    <div className="pb-3">
                      <div className="p-3 rounded shadow-sm bg-white">
                        <div className="d-flex border-bottom pb-3">
                          <div className="text-muted mr-3">
                            <img alt="#" src="img/SSL.PNG" className="img-fluid order_img rounded" />
                          </div>
                          <div className=''>
                            <p className="mb-0 font-weight-bold"><a href="restaurant.html" className="text-dark">ORDER #{order.orderId}</a></p>
                            <p className="mb-0 small">
                            <PDFDownloadLink document={<Invoice data={order}/>} fileName='invoice.pdf'>
                                <div style={styles.btn}>
                                    <HiOutlineDownload size={14} />
                                    <span>  Invoice </span>
                                </div>
                            </PDFDownloadLink></p>
                          </div>
                          <div className="ml-sm-auto button">
                            <p className={`bg-${activeTab === 'on Process' ? 'warning' : activeTab === 'Canceled' ? 'danger' : 'success'}  text-white py-1 px-2 rounded small mb-1 text-center`}>
                              {activeTab}
                            </p>
                            <p className="small font-weight-bold text-center "><i className="feather-clock"></i> {formatDate(order.createdAt)}</p>
                          </div>
                        </div>
                        <div className="pt-3  ">
                          <ul className="item p-1">
                            {order.items.slice(0, 2).map((item, id) => (
                              <li style={{ listStyleType: 'none', textDecoration: 'none' }} key={id}>
                                <div className="small ">
                                  <p className="text- font-weight-bold mb-0  ">{item.name} x {item.quantity}</p>
                                  <p className="text-muted mb-0">Price: ₹ {item.price}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {order.items.length > 2 && !expanded && (
                            <a href='#a' onClick={toggleExpand} className='mt-0'>Show more Items</a>
                          )}
                          {order.items.length > 2 && expanded && (
                            <ul className="item p-0 " >
                              {order.items.slice(2).map((item, id) => (
                                <li style={{ listStyleType: 'none', textDecoration: 'none' }} key={id}>
                                  <div className="small ">
                                    <p className="text- font-weight-bold mb-0  ">{item.name} x {item.quantity}</p>
                                    <p className="text-muted mb-0">Price: ₹ {item.price}</p>
                                  </div>

                                </li>
                              ))}
                              <a href='#a' onClick={toggleExpand}>Show fewer items </a>
                            </ul>)}
                        </div>
                        <div class="text-muted m-0 ml-sm-auto mr-3 small">Total Payment
                            <br></br> <span class="text-dark font-weight-bold">₹{order.totalPrice}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Orders;
