import React,{useEffect, useState} from 'react'
import { BiSolidCartAdd } from "react-icons/bi";
import MenuItem from './MenuItem';
import { useCart } from '../CartContext';
import MenuSection from './MenuSection';
import CartBox from './CartBox';
import { useSearch } from '../SearchContext';
import { useNavigate } from 'react-router-dom';

function SubCatItem(props) {
    const recommendedItem = props.recommendedItem;
    const navigate = useNavigate();
    const{searchData,handleSubCategoryChange, setSearchData, filter, handleFilterChange, 
      isAllSelected}= useSearch ();
    const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
    const cart = props.cart;
   const { totalPrice, itemQuantities,setItemQuantities, updateQuantity, newQuantity,updateTotalPrice,setTotalPrice, resetTotalPrice } = useCart();
   const[button,SetButton]= useState(false);
   const quantity = itemQuantities[recommendedItem._id]?.quantity || 0;
   
   
   const handleClick = (e) => {
    e.preventDefault();
    handleSubCategoryChange(recommendedItem._id);
    navigate('/product/'+recommendedItem.category);
   
  }
    
    
    
  return (
   
                <div className="cat-item px-1 py-3"  >
                    <a href="#"  onMouseEnter={toggleHover} onClick={handleClick}
      onMouseLeave={toggleHover} className={hovered ? 'bg-white rounded d-block p-2 text-center shadow-sm img-hover-scale' : 'bg-white rounded d-block p-2 text-center shadow-sm'}>
                        <img alt="#" src={window.location.origin+'/'+recommendedItem.imageUrl} className="img-fluid mb-2"/>
                        <p class="m-0 small">{recommendedItem.name}</p>
                    </a>
                </div>
                 
         
  )
}

export default SubCatItem