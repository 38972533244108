import React,{useState,useEffect} from 'react'
import { FaUserCircle } from "react-icons/fa";
import { _get } from '../services/apiClient';
import { ShopInfo } from '../shopinfo';

function RestInfo({setPageVisible}) {
 
    const [RestaurantInfo ,setRestaurentInfo] = useState({});
    const fetchData = async () => {
      try {
        const response = await _get(`/supplier/`);
        setRestaurentInfo(response.data.supplier);

      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle errors
      }
    };
  useEffect(()=>{
    fetchData();
  },[]);
  return (
    <>
     <div className="d-none col-md-none col-lg-none m-none">
        <div id="homeSection" className="p-3 d-flex justify-content-between d-md-none m-none " style={{backgroundColor:'CadetBlue'}}>
            <h4 className="font-weight-bold m-0 text-white">{ShopInfo.ApplicationHeader}</h4>
            <div className= "px-3" >   < a href="#" onClick={() => setPageVisible(true)}>
      <FaUserCircle style={{ fontSize: '30px', color: "darkred" }} />
              </a>    </div>
        </div>
        
    </div>
    <div className="offer-section py-4">
        <div className="container position-relative">
            <div className="pt-3 text-white">
                <h2 className="font-weight-bold">{ShopInfo.Name}</h2>
                <p className="text-white m-0">{ShopInfo.Address}</p>
                <div className="rating-wrap d-flex align-items-center mt-2">
                  
                </div>
            </div>
            <div className="pb-5">
                <div className="row">
                  
                    <div className="col-6 col-md-2">
                        <p className="text-white-50 font-weight-bold m-0 small">Open time</p>
                        <p className="text-white m-0">{ShopInfo.OpenTime}</p>
                    </div>
                    <div className="col-6 col-md-2">
                        <p className="text-white-50 font-weight-bold m-0 small">Close time</p>
                        <p className="text-white m-0">{ShopInfo.CloseTime}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default RestInfo