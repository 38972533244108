import React,{useState} from 'react';
import { IoMdCart } from "react-icons/io";
import { useCart } from '../CartContext';
import LanguageHandler from "./LanguageHandler";
import { FaUserCircle } from "react-icons/fa";



import axios from 'axios';
import Orders from './Orders';

function CartSection({size,loggedIn, setloggedIn, form,setForm,phoneNumber,setPhoneNumber, pageVisible,setPageVisible,ordersVisible,SetOrdersVisible,
   setCartVisible,isphoneNumber}) {
        
    const {totalPrice}= useCart();
    console.log(totalPrice);
   
 
    const [isVerified, setIsVerified] = useState(false);
    const [name,setName]= useState('') ;
   
   
    
    const handleLogout = () => {
      localStorage.removeItem('token');
     setloggedIn(false);
     SetOrdersVisible(false);
     setPageVisible(false);
    };
 
    const handleNext = ()=>{
    
        axios.post('http://localhost:3000/api/otp/getphoneno',  { phoneNumber: phoneNumber || form.phoneNumber })
        .then(response => {
       
          if (response.data.success) {
            console.log("OTP Generated successful");
            
          } else {
            console.log("OTP Generated failed");
           
          }
        })
        .catch(error => {
          
          console.error('Error sending data to server:', error);
         
        });
    

        setIsVerified(true);
        setPageVisible(false);
      }


  return (

    
    <header className="section-header d-md-block d-block  "style={{zIndex:1500}} >
      
        <section className="header-main shadow-sm bg-white  " >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-1 ">
                        <a href="#"  className="brand-wrap mb-0">
                            <img alt="#" className="img-fluid"  src={window.location.origin+'/img/logo_web.png'}/>
                        </a>
 
                    </div>
                    <div className="col-3 d-flex align-items-center " style={{position:'relative',bottom:'10px'}}>

                    </div>
                    <div className="col-3 ml-auto  d-md-block  d-block">
                        <div className="d-flex align-items-center justify-content-end mt-1 ">
                   
                            <a href="#"  className="" data-target="#cartBox"
                                data-toggle="modal">
                               <div></div>
                                

                            </a>
                           
                            <div className="icon d-flex align-items-center pt-2 m-none " style={{cursor:'pointer'}}>
                                
                                                   
                                <IoMdCart style={{ color: 'Salmon', fontSize: '30px' }} onClick={()=>setCartVisible(true)}/>
                                <div className="mr-2 mb-0  border rounded-pill bg-danger px-1 py-0 text-white shadow"
                                style={{position:'relative',bottom:'10px'}}>{size}
                               
                                </div>
                               
                                </div>
                               
                            
                                
                                 
                             
                                    
                        </div>
                        <div className="d-flex align-items-end justify-content-end m-none mb-2 mt-3">
                                    <div className="  " style={{ color: 'orange', fontSize: '15px',position:'absolute' ,left:'65%'}}>My Cart</div>
                                </div>
                       
                    </div>


                    
                </div>
               
                <div> 
               
                   
                </div>

                
              
               
               
            </div>

        </section>
    </header>
  )
}

export default CartSection