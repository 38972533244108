import React, { lazy, useState,useEffect } from 'react';
import {useParams} from 'react-router-dom';
import { useCart } from '../CartContext';
import CartBox from './CartBox';

//const currency = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" });
const currency = new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" });


function MenuItem(props) {
const { updateTotalPrice, totalPrice,itemQuantities, updateQuantity} = useCart();
const menuItem = props.MenuItem;
const cart = props.cart;


const [showQSection,setQSection]= useState(false)
const {id} = useParams();
const [price,setPrice] = useState (parseFloat(menuItem.price))
const [showCartButton,setShowCartButton]= useState(true)
const[inCart,SetinCart] = useState("false");
const quantity = itemQuantities[menuItem._id]?.quantity || 0;
const isAvailable = menuItem.isActive;
const isOffer = menuItem.offerDescription;


// const offerPrice =(menuItem.price - (menuItem.price * menuItem.oldPrice));
const handleIncreaseMinQuantity = (minquantity,price) => {
  const newQuantity = minquantity==0?1:minquantity;
  updateQuantity(menuItem._id,newQuantity,price);
  //updateTotalPrice(menuItem.price);
  setShowCartButton(false);
  props.handleClick(menuItem);
};

const handleIncreaseQuantity = () => {
    const newQuantity = quantity + 1;
    updateQuantity(menuItem._id,newQuantity,menuItem.price);
    //updateTotalPrice(menuItem.price);
  };
  const removeItem = (_id) => {
    const removedItem = cart.find(item => item._id === _id);
    const updatedCart = cart.filter(item => item._id !== _id);
    props.setCart(updatedCart);
    sessionStorage.setItem("cartValue", JSON.stringify(updatedCart));


   // updateTotalPrice(-removedItem.price);
    setShowCartButton(true);
  };
  const handleDecreaseQuantity = () => {
    if (quantity >= 0) {
      const newQuantity = quantity - 1;
      updateQuantity(menuItem._id, newQuantity,menuItem.price);
      if(newQuantity === 0){
        removeItem(menuItem._id);

      } else{

        //updateTotalPrice(-menuItem.price);
      }
      
    }
  };
     
  const handleAddToCart = () => {
    
    handleIncreaseQuantity();
    setShowCartButton(false);
     
 };

  return (
    (
    <div className="col-md-4 mb-3">
    <div

        className="d-flex align-items-center list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
        <div className="list-card-image">
         <div className='bg-white favourite-heart position-absolute font-weight-bold'>
         
          </div>
           
           {isAvailable === 0 && (<div className='card-badge'> SOLD OUT</div>)} 
           {isOffer && (<div className='offer-badge'> {isOffer}</div>)} 


            <img loading="lazy" alt="#"  src={window.location.origin+'/'+menuItem.imageUrl} className="img-fluid item-img w-100" />

        </div>
        <div className="p-3 position-relative">
            <div className="list-card-body">


                <h6 className="mb-1"><span className="text-black text-wrap">{menuItem.name}
                    </span>
                </h6>
                <p className="text-gray text-wrap mb-3">{menuItem.heighlightdescription}</p>


                {isOffer&&(<div style={{textDecoration:'line-through',color:'red' }} className='d-flex allign item-center pl-2 pb-1 pt-0'>
                          {currency.format(menuItem.price)}
                          </div>
                         )}
                <p className="text-gray mb-3 time">

                    <span className="bg-light text-dark bg-white rounded-sm pl-2 pb-1 pt-1 pr-2">
                         
                        <strong className="h6 font-weight-bolder" >
                        {currency.format( menuItem.price)}
                    </strong>
                    { menuItem.quantitySections?.length>0 && (<a  style={{cursor: 'pointer'}} onClick={()=>{setQSection(!showQSection); return false;}} > View Offers</a>) }
                    </span>
                   
                </p>
             
              
                { showQSection && menuItem.quantitySections?.map((offeritem, index1) => (
                <p className="text-gray mb-3 time"><span className="bg-light text-dark bg-white rounded-sm pl-2 pb-1 pt-1 pr-2">
                <a  style={{cursor: 'pointer'}} onClick={()=>{handleIncreaseMinQuantity(offeritem.minQuantity,offeritem.price); return false;}} >{'ADD ' +offeritem.name}</a></span>
                </p>
                ))}
          
            </div>
           
           
            <div>
            
          
               {showCartButton && (<div className="container col-lg-12 col-md-6"> 
                <span  className="float-left "
                     ng-if="">
                       <button className="btn custom-rounded-btn px-3 btn-sm text-white fw-bold"   style={{backgroundColor:'Salmon' ,fontWeight:'bold'}}  data-toggle="modal"
                       onClick={() => {props.handleClick(menuItem);
                        handleAddToCart();
                        }}
                        data-target="#extras"
                        >ADD </button>
                      
                </span>
                
                </div>
                )}

                
                {!showCartButton && (<div className="d-flex flex-column align-items-center mt-2">
                <div ng-if="(menuItem.quantitySections==null||menuItem.quantitySections.length==0)&&SelectedQuantity(menuItem) > 0">
                    <div className="btn-group" role="group" aria-label="Basic example">
                    
                    <button type="button"
                        className="btn-sm inc btn btn-outline-secondary"  onClick={handleDecreaseQuantity}>
                         <i className="feather-minus"></i>
                    </button>
                    <a href="#" className="btn-sm btn btn-outline-secondary division" data-toggle="modal"
                        ng-click="showQuantityMenu(menuItem,Item.SectionName)"
                        data-target="#extras" style={ {display : 'block'} }>{quantity}</a>
                        <button  type="button" 
                    className="btn-sm  dec btn btn-outline-secondary"onClick={handleIncreaseQuantity}> 
                      <i className="feather-plus"></i>
                    </button>
                    <span 
                    ng-if="menuItem.quantitySections.length>0 && SelectedQuantity(menuItem)>0">
                    
                    </span>

                    
                    </div>
                    </div>

                    <div className="text-center mt-2">
                    <span 
                    ng-if="(menuItem.quantitySections==null||menuItem.quantitySections.length==0)&&SelectedQuantity(menuItem)==0">
                    
                   </span>
                
                  </div>
                    
                  
                </div> 
                
                )}

                
            </div>
            
        </div>
        
    </div>
   
</div>)

  )
}


export default MenuItem