import React, { useState } from 'react';
import axios from 'axios';
import MenuItem from './MenuItem';
import MenuSection from './MenuSection';
import { useCart } from '../CartContext';
import { FaAngleDoubleDown } from "react-icons/fa";
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Orders from './Orders';
import { IoArrowBackCircle } from "react-icons/io5";
import useTranslation from "./customHooks/translations";
import { IoClose } from "react-icons/io5";
import { ShopInfo } from '../shopinfo';


const CartBox = ({ cart, setCart, form, setForm, cartVisible, setCartVisible, size, phoneNumber, setPageVisible, pageVisible, loggedIn }) => {
  const { totalPrice, itemQuantities, updateQuantity, updateTotalPrice, setTotalPrice, resetTotalPrice } = useCart();
  const [showForm, setShowForm] = useState(false);
  const [show, setShow] = useState(true);
  const [text, setText] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const translation = useTranslation();
  console.log('cartv', cartVisible);
  const removeItem = (_id) => {
    const removedItem = cart.find(item => item._id === _id);
    const updatedCart = cart.filter(item => item._id !== _id);
    setCart(updatedCart);
    sessionStorage.setItem("cartValue", JSON.stringify(updatedCart));
    //{ removedItem.OfferDescription ? updateTotalPrice(-(removedItem.price)) : updateTotalPrice(-removedItem.price) };
  };
  const handleShow = () => {
    setShowForm(true);

  }
  const handleShowCart = () => {
    setShow(false);

  }
  const handlehideCart = () => {
    setShow(true);

  }
  const handleCloseClick = () => {
    console.log('button is clicked', cartVisible);
    setCartVisible(false);
    //window.location.reload();
  }


  const handleFormChange = (e) => {
    const { id, value } = e.target;
    setForm((prevData) => ({ ...prevData, [id]: value }));
  };
  const handleText = (e) => {
    setText(e.target.value);
  }
  const handleTextSubmit = () => {
    const { message } = text;
    setText("");
    enqueueSnackbar(`Thank you for your Suggestion !`, { variant: 'success' })
  }

  const handleFormSubmit = () => {
    const { name, phoneNumber, email, address } = form;

    if (!name || !phoneNumber || !email || !address) {
      setShowForm(true);
      setShow(false);

      enqueueSnackbar(`Please fill in all required fields.`, { variant: 'error' })
    } else {
      enqueueSnackbar(`Thank you ${name} for your Order !`, { variant: 'success' })
      setForm({
        name: '',
        phoneNumber: '',
        email: '',
        address: '',
      });
      setShowForm(false);
      handlePostClick();
      setCart([]);
      sessionStorage.setItem("cartValue", []);
      resetTotalPrice();
      setShow(true);
    }
  };

  const handleClose = () => {
    setCartVisible(false);
  }

    const PlaceOrderInWhatsapp = () => {
        window.open("https://wa.me/" + ShopInfo.Whatsapp + "?text=" + encodeURIComponent(GenerateWhatsappData()), '_blank');
    }
    const GenerateWhatsappData = () => {

        var itemsSelected = ShopInfo.WhatsappMessageHeader + "\n";
        if (cart?.length > 0) {
            cart.forEach((item, index) => {

                //itemsSelected += index + 1 + ") " + item.name + "value.Portion" + " - " + itemQuantities[item._id] || 0 + "\n"
                itemsSelected = itemsSelected + (Number(index) + 1) + ") " + item.name + " - " + (itemQuantities[item._id]?.quantity || 0) + "\n";
            });
            // angular.forEach($scope.selectedItems, function (value, index) {
            //   itemsSelected += index + 1 + ") " + value.ItemName + value.Portion + " - " + value.Quantity + "\n";
            // });
        }
        itemsSelected = itemsSelected + "Total : ₹ " + toPay;
        //console.log(itemsSelected);
        // var suggestions = $("#txtUserSuggetions").val();
        // if (suggestions) {
        //   itemsSelected += "Customer Suggestions :- " + suggestions + ".";
        // }
        //$scope.redirectToWhatsApp(itemsSelected);
        return itemsSelected;
    }

  const handlePostClick = async () => {
    try {
      const response = await axios.post('http://localhost:3000/api/cart', {
        phoneNumber: form.phoneNumber,
        items: cart.map(item => ({
          name: item.name,
          _id: item._id,
          quantity: itemQuantities[item._id]?.quantity || 0,
          price: itemQuantities[item._id]?.price,
          image: item.imageUrl,
        })),
        message: text,

        totalPrice: toPay
      });

      console.log('Order placed successfully:', response.data);
      setCart([]);
      sessionStorage.setItem("cartValue", JSON.stringify([]));
      if (setForm({
        name: '',
        phoneNumber: '',
        email: '',
        address: '',
      })) {
        setShowForm(true);
      } else {
        setShowForm(false);
      }


    } catch (error) {
      console.error('Error placing order:', error);
      enqueueSnackbar('Failed to place order. Please try again later.', { variant: 'error' });
    }
  };
  const totalPriceNumber = parseFloat(totalPrice);
  let toPay = totalPriceNumber;



  return (
    <SnackbarProvider>

      {cartVisible && (<div
        className='col-md-6 col-lg-12 col-4 border border-4 border-black  mt-0 translate-middle mt-20 container position-absolute  border border-4'
        //id="cartBox"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cartBoxLabel"

      >

        <div className="modal-dialog  mt-5  mx-auto shadow-lg border border-4 border-black"
          role="document" style={{ zIndex: 1200, top: '-35px' }} >
          <div className="modal-content shadow-lg border border-4 border-black">
            <div className="modal-header">
              <h5 className="modal-title" id="cartBoxLabel">
                <a href="#" className="brand-wrap mb-0">
                                  <img alt="#" className="img-fluid" src={window.location.origin + '/img/logo_web.png'} />
                </a>
              </h5>
              <div className='px-2 pb-5' style={{ position: 'absolute', top: '5px', right: '10px', fontSize: "20px" }}>
                <span><IoClose onClick={() => { setPageVisible(false); setCartVisible(false) }} /></span>
              </div>
            </div>

            <div className="modal-body" >
              <div className='container'>
                <div className="row">

                  <div className="col-md-12 col-lg-12 col-12 order-md-last">

                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                      <span className="text-primary">{translation.yourcart}</span>
                      <span className="badge bg-primary rounded-pill">{size}</span>
                    </h4>

                    {size === 0 && (
                      <div>
                        <h6 className="text-danger mb-0 text-center">{translation.cartisempty}</h6>
                        <div className="bg-white p-3 py-3 border-bottom clearfix">
                          <div className="mb-0 input-group">
                            <div className="input-group-prepend" onClick={handleTextSubmit}>
                              <span className="input-group-text"><i className="feather-message-square"></i></span>
                            </div>
                            <textarea
                              id="txtUserSuggestions"
                              name='message'
                              placeholder="Any suggestions? We will pass it on..."
                              aria-label="With textarea"
                              className="form-control"
                              value={text}
                              onChange={handleText} >

                            </textarea>
                          </div>
                        </div>
                      </div>
                    )}

                    {size > 0 && (

                      <ul className="list-group mb-3">
                        {cart.map((item, index) => (

                            <li key={index} className="list-group-item d-flex justify-content-between lh-sm ">
                                <div className="col-7 col-md-7 col-lg-7">
                                    <span className="my-0">{item.name}</span>

                                    <small className="text-body-secondary number font-weight-bold fs-5 ">{item.heighlightdescription}</small>
                                </div>

                                <div className="col-3 col-md-3 col-lg-3 d-flex align-items-end justify-content-center">
                              <div className="btn-group" role="group" aria-label="Basic example">

                                <button
                                  type="button"
                                  className="btn-sm left inc btn btn-outline-secondary"
                                  onClick={() => {
                                    const newQuantity = Math.max(0, itemQuantities[item._id]?.quantity - 1);

                                    updateQuantity(item._id, newQuantity,item.price);
                                    
                                    if (newQuantity === 0) {
                                      removeItem(item._id);

                                    } else {
                                      //updateTotalPrice(-item.price);
                                    }
                                    
                                  }}
                                >
                                  <i className="feather-minus"></i>
                                </button>
                                <a
                                  href="#"
                                  className="btn-sm btn btn-outline-secondary division"
                                  data-toggle="modal"
                                  data-target="#extras"
                                  style={{ display: 'block' }}
                                >
                                  {itemQuantities[item._id]?.quantity || 0}
                                </a>
                                <button
                                  type="button"
                                  className="btn-sm left inc btn btn-outline-secondary "
                                  onClick={() => {
                                    const newQuantity = Math.max(0, itemQuantities[item._id]?.quantity + 1);
                                    updateQuantity(item._id, newQuantity,item.price);
                                   
                                    //{ item.offerDescription ? updateTotalPrice((item.price)) : updateTotalPrice(item.price) };
                                  }}
                                >
                                  <i className="feather-plus"></i>
                                </button>

                              </div>
                            </div>
                            <span className="text-body-secondary number font-weight-bold fs-5">₹{itemQuantities[item._id]?.price}</span>

                          </li>
                        ))}
                      </ul>
                    )}
                    <hr />
                    <div className='d-flex justify-content-between mb-3 '>
                      {translation.ItemTotal}
                      <span> ₹{totalPrice} </span>
                    </div>


                    <hr />
                    {/* <div>
                      <h6 className="font-weight-bold mb-0">{translation.TOPAY} <span className="float-right">₹{toPay}</span></h6>
                      <hr />
                    </div> */}
                  </div>
                </div>

                <div >
                  <div >



                    <div className='container'>
                      <form>

                        <div className="mb-3">
                          <label htmlFor="phoneNumber" className="form-label">Phone Number:</label>
                          <input type="tel"
                            className={`form-control ${!phoneNumber && !showOtp && `is-invalid`}`} id="phoneNumber" name="phoneNumber" onChange={handleFormChange}
                            required />

                        </div>


                      </form>

                    </div>
                  </div>
                </div>




                <div className="d-flex justify-content-center ">
                  <button onClick={() => { PlaceOrderInWhatsapp(); }} type="button" className="btn btn-success btn-lg btn-block "  //{/*add !loggedIn */}
                    data-bs-toggle="modal" data-bs-target="#modalSheet" id="#modalSheet">

                    Place Order on Watsapp
                    {/*  {!loggedIn ? "Please Verify Your Phone Number" : "Confirm Order"} */}

                  </button>



                </div>


              </div>

            </div>
          </div>
        </div>
      </div>)}

      <div>
        {!show && (
          <div className='container'  >
            <div className="modal  modal-sheet position-fixed d-block bg-body-secondary  row " tabindex="-1" role="dialog" id="modalSheet"
              style={{ zIndex: '1200' }}>
              <div className="modal-dialog" role="document"  >
                <div className="modal-content rounded-4 shadow col-12 " style={{ height: "660px", width: "540px", overflowY: "auto" }}>
                  <div className="modal-header border-bottom-0 text-center">
                    <a onClick={handlehideCart} style={{ color: "darkred", fontSize: '30px' }}> <IoArrowBackCircle />  </a>
                    <h3 className="modal-title fs-5 pt-4">Order Details</h3>
                    <a href='# cartBox' icontype="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    />
                  </div>
                  <div class="modal-body py-0">
                    <h6 className='pb-3 px-3'>Your Order</h6>


                    <div className="table-responsive ">
                      <table className='table table-bordered '>
                        <thead>
                          <tr className='text-center '>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody className='text-center '>
                          {cart.map((item, index) => (
                            <tr key={index}>
                              <td>{item.Item}</td>
                              <td>{itemQuantities[item._id]?.quantity || 0}</td>
                              <td>₹{item.price}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div >



                    <div className='p-3'>
                      <h6>Contact Details</h6>
                      <hr />
                      <div> {form.name}</div>
                      <div> {phoneNumber || form.phoneNumber}</div>
                      <div> {form.email}</div>
                      <div> {form.address}</div>

                    </div>
                  </div>


                  <div class="modal-footer flex-column align-items-stretch w-100 gap-2 pb-3 border-top-0 ">
                    <button type="button" onClick={handleFormSubmit} class="btn btn-lg btn-primary   ">Place Order</button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>


    </SnackbarProvider>
  );
};

export default CartBox;
