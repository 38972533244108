import React, {Suspense,lazy,useEffect,useRef, useState}from 'react';
import { useSearch } from '../SearchContext';
import axios from 'axios';

import useTranslation from "./customHooks/translations";
import { useParams } from 'react-router-dom';
import { _get } from '../services/apiClient';

const MenuItem = lazy(() => import('./MenuItem'));


function MenuSection({ handleClick,cart,setCart,products,subCategories}) {
    const {searchData,filtersubCategory, filter} = useSearch();
    console.log(filtersubCategory);
    const [categories, setCategories] = useState([]);
    const {id} = useParams();
    //const {categoryname}=subCategories?.[0].category;
    let filteredItemsFound = 0;
    const [ MenuList,setMenuList] = useState([]);
    const [ categoryname,setCategoryName] = useState('');
    const [observer, setObserver] = useState(null);
    const elementRef = useRef(null);
    const translation = useTranslation();

    const fetchCategoriesData = async () => {
        try {
          const response = await _get(`/categories/`);
          setCategories(response.data.categoryList);
          setCategoryName(response.data.categoryList.filter(x=>x.id==id)[0].name);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle errors
        }
      };

   useEffect(() => {
    fetchCategoriesData();
       const options = {
           root: null,
           rootMargin: '0px',
           threshold: 0.1 
       };

       const handleIntersection = (entries) => {
           entries.forEach(entry => {
               if (entry.isIntersecting) {
                   loadMenuItem();
                   observer.unobserve(entry.target);
               }
           });
       };
       const observer = new IntersectionObserver(handleIntersection, options);
       if (elementRef.current) {
           observer.observe(elementRef.current);
       }
       setObserver(observer);
       return () => {
           if (observer) {
               observer.disconnect();
           }
       };
   }, []);
  const loadMenuItem = () => {
      import('./MenuItem').then(mod => {
        
      });
  }   
   useEffect(()=>{
         
        },[])

    return (
        <>
      
      
        <div className="container position-relative">

            <div className="p-3 m-0 bg-light sticky-top w-100 top-heightset"> 
            <div class="page-breadcrumb">
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active">{categoryname}</li>
                        </ul>
                    </div>
           
            </div>
      
            
            <div>
            {subCategories.map((section, index) => {
                
                const filteredSectionItems = products.filter(item => {
                    return (

                            (item.subCategoryName==section.name &&((!searchData && !filtersubCategory) || (
                                (searchData && item.name.toLowerCase().includes(searchData.toLowerCase())
                                ||
                                 (filtersubCategory && item.subCategoryId==filtersubCategory))
                            
                            )
                        )
                            )
                    );
                   
                });
              

                if (filteredSectionItems.length !== 0)
                {
                   filteredItemsFound = 1;
                  
                   
                      return (
                    <div key={index}>
                        <div className="w-100" id={`SectionName${section.id}d`} style={{ paddingTop: '5px' }}
                            onClick={() => handleClick(section)}>
                        </div>
                        <h6 className="p-3 m-0 bg-light w-100 top-heightset" name="fixedMenuHeader" >
                            {section.name} 
                            {/* <small className="text-black-50">  {filteredSectionItems.length} ITEMS </small> */}
                        </h6>
                        <div className="most_sale">
                            <div className="row mb-3 mt-5">
                        <Suspense fallback={<div><p><i>Loading...</i></p> </div>}>

                                {filteredSectionItems.map((detail, detailIndex) => (
                                    
                                    <MenuItem
                                        key={detailIndex}
                                        MenuItem={detail}
                                        handleClick={handleClick}  
                                        cart={cart}
                                        setCart={setCart}
                                    />
                                   
                                ))}
                                </Suspense>
                            </div>
                        </div>
                        
                       
                    </div>
                    );
                }
                else
                {
                    return null;
                }
            })}</div>
            {filteredItemsFound !== 1 && (
                <div>
                    <h6 className="p-3 m-0 bg-white text-danger w-100">{translation.filterditem}</h6>
                </div>
            )}
            <div className="dropdown dropup " style={{ position: 'fixed', bottom: '70px', right: '30px', zIndex: '1000' }}>
                <button id="menucategories" className="btn btn-dark dropstart btn-sm rounded-pill px-2 py-1 dropdown-toggle text-dark small font-weight-bold d-flex align-items-center"
                    type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <p className="h6 text-center mt-0 text-white">
                        <i className="feather-list"></i> Category
                    </p>
                </button>
               <div className='container'>
            <ul className=" dropdown-menu  custom-dropdown-menu dropdown-menu.show dropdown-menu border pb-2 text-dark shadow " style={{position: 'absolute', zIndex: '1022' }}>
                
                    <li className="dropdown-header h5 fw-bold text-dark bg-secondary-subtle division">Category List</li>
                    <hr className="dropdown-divider" />
                    {categories.map((section, index) => (
                        <li key={index}>
                        <a className={section.id==id?"dropdown-item d-flex justify-content-between rounded-2 active":"dropdown-item d-flex justify-content-between rounded-2 "}  href={'/product/'+section.id}>
                                 <span>{section.name}</span>
                                 {/* <span className='fw-bold' style={{ fontWeight: 'bold' }}>{section.MenuItem.length}</span> */}

                        </a>
                        
                        </li>
                    ))}
                </ul>
                </div>
                {/* <div className='arrow' style={{position:'absolute'}}></div> */}
            </div>            
        </div>
             </>
    );
    
}
    
    export default MenuSection;
    
