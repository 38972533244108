export default {
  featuredItem: "TOP-ARTIKEL",
  yourcart:"Ihr Warenkorb",
  cartisempty: "Ihr Warenkorb ist jetzt leer.",
  ItemTotal:"Artikel Gesamt",
  TOPAY:"zahlen",
  CustomerDetails:"Kundendetails",
  PlaceOrder:"Bestellung aufgeben",
  filterditem:"Entschuldigung, wir konnten nichts finden!"
};
