
import React, {Suspense,lazy,useEffect,useRef, useState}from 'react';
import RestInfo from '../Components/RestInfo'
import SearchSection from '../Components/SearchSection'
import MenuSection from '../Components/MenuSection'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { _get } from '../services/apiClient';
import { useSearch } from '../SearchContext';

function Product({ handleClick,cart,setCart,handleCartClick}) {
    const [products, setProducts] = useState([]);
    const [offset, setOffset] = useState(1);
    const [totalPages, settotalPages] = useState(1);
    const [subCategories, setsubCategories] = useState([]);
    const {id} = useParams();
    const{filtersubCategory}= useSearch ();
   
    
    useEffect(()=>{
      fetchSubCategoryData();
    },[])
    const goToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth",
      });
  };
    useEffect(()=>{
      goToTop();
      setOffset(1);
      fetchData(1);
    },[filtersubCategory])
    
    useEffect(()=>{
        fetchData(0);
      },[offset])

      useEffect(() => {
       
        const handleScroll=(e)=>{
const scrollHeight=e.target.documentElement.scrollHeight;
const currentHeight=e.target.documentElement.scrollTop+window.innerHeight;
if(currentHeight+1>=scrollHeight){
  setOffset(offset+1);
}
        }
        window.addEventListener("scroll",handleScroll);
      }, [offset])
      
      const fetchData = async (isReset) => {
        try {
          if((offset<= totalPages)||isReset){
          const response = await _get(`/products/catName?&catId=${id}&subcatId=${filtersubCategory}&perPage=10&page=${isReset?1:offset}`);
         // setProducts(response.data.products);
         if(isReset || offset==1){
          setProducts(response.data.products);
          settotalPages(response.data.totalPages);
         }
          else
          setProducts(pre=>[...pre,...response.data.products]);
        }
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle errors
        }
      
      };
      const fetchSubCategoryData = async () => {
        try {
          const response = await _get(`/categories/getSubcategories/${id}`);
          setsubCategories(response.data);

        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle errors
        }
      };
  return (
    <>
                <SearchSection handleClick={handleClick} catId={id} subCategories={subCategories} cart={cart} />
                <MenuSection products={products} subCategories={subCategories} 
                  handleClick={handleClick}
                  handleCartClick={handleCartClick}
                  cart={cart} setCart={setCart}
                />  
                </>
  )
}

export default Product