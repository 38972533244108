
import React, {Suspense,lazy,useEffect,useRef, useState}from 'react';
import RecomendedItem from '../Components/RecomendedItem';
import RecomItems from '../Components/RecomItems';
import SubCategoryItems from '../Components/SubCategoryItems';
import { _get } from '../services/apiClient';
function Home({cart}) {
    const [categories, setCategories] = useState([]);
    
    const [subCategories, setsubCategories] = useState([]);
    const [hovered, setHovered] = useState(false);
    const toggleHover = () => setHovered(!hovered);
  
         useEffect(()=>{
            fetchCategoriesData();
            fetchSubCategoriesData();
          },[])
          const fetchCategoriesData = async () => {
            try {
              const response = await _get(`/categories/`);
              setCategories(response.data.categoryList);
    
            } catch (error) {
              console.error('Error fetching data:', error);
              // Handle errors
            }
          };
          const fetchSubCategoriesData = async () => {
            try {
              const response = await _get(`/categories/getSubcategories`);
              setsubCategories(response.data);
    
            } catch (error) {
              console.error('Error fetching data:', error);
              // Handle errors
            }
          };
  return (
    
    <div className='container'>
       <SubCategoryItems recommendedItemLists={subCategories} cart={cart} /> 
        {/* <RecomItems  recommendedItemLists={recommendedItem}/> */}
       <div className='container'>
       <div class="pt-4 pb-2 title d-flex align-items-center"><h5 class="m-0">Browse By Category</h5>
       <a class="font-weight-bold ml-auto" style={{display:'none'}} href="/product/670104819c46e74cc8eff406">View all <i class="feather-chevrons-right"></i></a></div>
     <div className='row'>
    {/* 
<div className="cat-slider"  > */}
        {
              
    categories.map((item,index) => (
       
       

        <div className="cat-item px-2 py-3 d-flex flex-row"  >
        <a  href={"/product/"+item.id} onMouseEnter={toggleHover} style={{background:item.color}}
onMouseLeave={toggleHover} className={hovered ? ' rounded d-block p-2 text-center shadow-sm img-hover-scale' : ' rounded d-block p-2 text-center shadow-sm'}>
            <img alt="#" src={item.imageUrl} className="img-fluid mb-2"/>
            <h6>{item.name}</h6>
        </a>
    </div>
  
   
   
      ))}
      </div>
       {/* </div> */}
       </div>
 
     
  
      <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="banner-img wow animate__ animate__fadeInUp animated" data-wow-delay="0" >
                            <img src="img/banner-1.png" alt=""/>
                            <div className="banner-text">
                                <h4>
                                    Everyday Fresh &amp; <br/>Clean with Our<br/>
                                    Products
                                </h4>
                                {/* <a href="/product" className="btn btn-xs">Shop Now <i className="fi-rs-arrow-small-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="banner-img wow animate__ animate__fadeInUp animated" data-wow-delay=".2s" >
                            <img src="img/banner-2.png" alt=""/>
                            <div className="banner-text">
                                <h4>
                                    Make your Breakfast<br/>
                                    Healthy and Easy
                                </h4>
                                {/* <a href="/product" className="btn btn-xs">Shop Now <i className="fi-rs-arrow-small-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 d-md-none d-lg-flex">
                        <div className="banner-img mb-sm-0 wow animate__ animate__fadeInUp animated" data-wow-delay=".4s" >
                            <img src="img/banner-3.png" alt/>
                            <div className="banner-text">
                                <h4>The best Organic <br/>Products Online</h4>
                                {/* <a href="/product" className="btn btn-xs">Shop Now <i className="fi-rs-arrow-small-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  <br/>
  <br/>
         </div>
       
        
  )
}

export default Home