export default {
  
  featuredItem: "FEATURED ITEMS",
  yourcart:"Your cart",
  cartisempty: "Your cart is empty now. Add items to place an order.",
  ItemTotal:"Item Total",
  TOPAY:"TO PAY",
  CustomerDetails:"Customer Details",
  PlaceOrder:"Place Order",
  filterditem:"Sorry, We couldn't find anything!"
};
