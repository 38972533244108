
import MenuSection from './MenuSection';
import { useSearch } from '../SearchContext';
import SubCategoryItems from './SubCategoryItems';

import React, {Suspense,lazy,useEffect,useRef, useState}from 'react';

function SearchSection(props) {
    const{searchData,handleSearchChange, setSearchData, filter, handleFilterChange, 
         isAllSelected}= useSearch ();
         const cart = props.cart;
    return (
        
      <div className="container p-0 pt-2 sticky-top " style={{zIndex: 999}} id="searchboxSection">
             <div className="p-1  mt-n3 rounded position-relative "  style={{ backgroundColor: '#f8f9fa'}}>
                <div className="input-group mt-1 mb-1 rounded shadow-sm overflow-hidden ">
                   
                    <input
                        id="searchbox"
                        type="search"
                        className="shadow-none border-0 form-control"
                        onChange={handleSearchChange}
                        placeholder="Search product"
                        value={searchData}
                    />
                    
                </div>
               
                    <SubCategoryItems cart={cart} recommendedItemLists={props.subCategories} />
          


            </div>
          
          
            
        </div>
    );
}

export default SearchSection;
