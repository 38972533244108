import React, { useState, useEffect } from 'react';
import './App.css';
import Footer from './Components/Footer';
import CartSection from './Components/CartSection';
import MenuSection from './Components/MenuSection';
import SearchSection from './Components/SearchSection';
import RestInfo from './Components/RestInfo';
import CartBox from './Components/CartBox';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CartProvider } from './CartContext';
import { SearchProvider } from './SearchContext';
import Orders from './Components/Orders';
import Home from './pages/Home';
import Product from './pages/Product';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


function App() {
  const [cart, setCart] = useState( sessionStorage.getItem('cartValue') == null ? [] : JSON.parse(sessionStorage.getItem('cartValue')));
  const [cartVisible, setCartVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pageVisible, setPageVisible] = useState(false);
  const[ordersVisible,SetOrdersVisible]=useState(false);
  const [loggedIn, setloggedIn] = useState(false); 

  const [form, setForm] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    address: '',
  });
  console.log('visible',pageVisible);
console.log(phoneNumber);
  const handleClick = (menuItem) => {
    const isDuplicate = cart.some((item) =>
      item._id === menuItem._id && item.name === menuItem.name //&& item.isRecommendedItem
    );
    if (!isDuplicate) {
      setCart([...cart, menuItem]);
      sessionStorage.setItem("cartValue", JSON.stringify([...cart, menuItem]));
      //setCartVisible(true);
    } else {
      enqueueSnackbar('Item already Added', { variant: 'error' });
    }
  };

  const handleCartClick = () => {
    setCartVisible(!cartVisible);
  };

 
  


  return(
    <SnackbarProvider>
    <CartProvider>
      <SearchProvider>
        <>
        
              <CartSection
                size={cart.length}
                handleCartClick={handleCartClick}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                pageVisible={pageVisible}
                setCartVisible={setCartVisible}
                setPageVisible={setPageVisible}
                form={form}
                setForm={setForm}
                loggedIn={loggedIn}
                setloggedIn={setloggedIn}
                SetOrdersVisible={SetOrdersVisible}
              />    {ordersVisible  &&<Orders  SetOrdersVisible={SetOrdersVisible} setPageVisible={setPageVisible}  />}
            {!ordersVisible &&  (
              <>
                <CartBox
                  cart={cart}
                  setCart={setCart}
                  size={cart.length}
                  cartVisible={cartVisible}
                  setCartVisible={setCartVisible}
                  handleCartClick={handleCartClick}
                  phoneNumber={phoneNumber}
                  setPageVisible={setPageVisible}
                  pageVisible={pageVisible}
                  form={form}
                  setForm={setForm}
                  loggedIn={loggedIn}
                />
                {/* <RestInfo setPageVisible={setPageVisible} /> */}
               
                <BrowserRouter>
        <Routes>
              <Route path="/" element={ <Home handleClick={handleClick}
                  handleCartClick={handleCartClick}
                  cart={cart} />} />
              <Route path="/product/:id" element={<Product handleClick={handleClick}
                  handleCartClick={handleCartClick}
                  cart={cart} setCart={setCart} setCartVisible={setCartVisible} /> } />            </Routes>
            </BrowserRouter>
               
                <Footer size={cart.length} setCartVisible={setCartVisible} />
              </>
            )}
          </>
        </SearchProvider>
      </CartProvider>
    </SnackbarProvider>
  );
}

export default App;