import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import SubCatItem from "./SubCatItem";
function SubCategoryItems({recommendedItemLists,cart}) {
const [slideToShow,SetSlideToShow]= useState(8);

useEffect(()=>{
  const handleSize = ()=>{
    
    console.log(window.innerWidth);
        SetSlideToShow(Math.ceil((window.innerWidth)/300))
  }
  window.addEventListener('resize',handleSize);
  handleSize();
  return()=>{
    window.removeEventListener('resize',handleSize);
  }
},[])
 
var settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  autoplay: false,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  className:"cat-item px-1 py-3",
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        className:"cat-item px-1 py-3",
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
        autoplay: false,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  variableWidth: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        className:"cat-item px-1 py-3",
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        autoplay: false,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  variableWidth: true
      }
    },
    {
      breakpoint: 480,
      settings: {
        className:"cat-item px-1 py-3",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  variableWidth: true
      }
    }
  ]
};
  // const settings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 5000,
  //   slidesToShow: slideToShow,
  //   // slidesToScroll: slideToShow,
  //   autoplay: false,      
  //   autoplaySpeed: 8000   ,
  //   rtl:false
  // };
 
  return (
    <div className="cat-slider"  >
      <Slider {...settings}>
      {
        recommendedItemLists.map((item,index) => (
              <SubCatItem
              key ={index}
              recommendedItem={item}
              
              cart={cart}
               />))}
      </Slider>
    </div>
  );
}

export default SubCategoryItems;
